import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import {
    faAddressCard,
    faAlarmClock,
    faAlarmPlus,
    faAnalytics,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faArchive,
    faArrowCircleDown,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowRotateRight,
    faArrowToLeft,
    faArrowToRight,
    faArrowUp,
    faArrowUp91,
    faArrowsRotate,
    faAsterisk,
    faBadgeCheck,
    faBallotCheck,
    faBan,
    faBars,
    faBell,
    faBellPlus,
    faBellSlash,
    faBook,
    faBookOpen,
    faBookReader,
    faBoxes,
    faBracketsCurly,
    faBrush,
    faBuilding,
    faCakeCandles,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarCircleUser,
    faCalendarDay,
    faCalendarEdit,
    faCalendarTimes,
    faCalendarWeek,
    faCamcorder,
    faCamera,
    faCancel,
    faCarSide,
    faCertificate,
    faChalkboardTeacher,
    faChartBar,
    faChartSimple,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faCheckSquare,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleQuestion,
    faCircleSort,
    faCity,
    faClipboardList,
    faClock,
    faCloudDownload,
    faCloudUpload,
    faCodeCompare,
    faCog,
    faCogs,
    faComment,
    faCommentAltDollar,
    faCommentAltLines,
    faCommentDots,
    faCommentPlus,
    faCommentsAlt,
    faCommentsAltDollar,
    faCompressAlt,
    faCopy,
    faCube,
    faCubes,
    faDigging,
    faDownload,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faEnvelopeCircleCheck,
    faEnvelopeOpen,
    faEnvelopeOpenDollar,
    faEnvelopeOpenText,
    faEraser,
    faExchange,
    faExclamationCircle,
    faExclamationSquare,
    faExclamationTriangle,
    faExpandAlt,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFamily,
    faFile,
    faFileArchive,
    faFileAudio,
    faFileCertificate,
    faFileChartColumn,
    faFileCheck,
    faFileExcel,
    faFileExport,
    faFileImage,
    faFileImport,
    faFileInvoiceDollar,
    faFilePdf,
    faFilePowerpoint,
    faFileSignature,
    faFileVideo,
    faFileWord,
    faFiles,
    faFilm,
    faFlag,
    faFolder,
    faFolderOpen,
    faFolderPlus,
    faFolderTree,
    faFont,
    faFunction,
    faGavel,
    faGearComplexCode,
    faGlobeEurope,
    faGraduationCap,
    faHandHoldingUsd,
    faHandPointer,
    faHandsUsd,
    faHdd,
    faHighlighterLine,
    faHistory,
    faHome,
    faHomeAlt,
    faIdBadge,
    faImage,
    faInboxIn,
    faInboxOut,
    faInfo,
    faInfoCircle,
    faKey,
    faLanguage,
    faLayerGroup,
    faLightEmergencyOn,
    faLineColumns,
    faLink,
    faList,
    faListAlt,
    faListOl,
    faListRadio,
    faListTimeline,
    faListUl,
    faLocation,
    faLocationDot,
    faLock,
    faLockOpen,
    faMap,
    faMarker,
    faMaximize,
    faMemoCircleInfo,
    faMicrophone,
    faMinimize,
    faMinus,
    faMoneyBill,
    faMoneyCheckAlt,
    faMoneyCheckEdit,
    faMoneyCheckEditAlt,
    faMusic,
    faObjectGroup,
    faPage,
    faPager,
    faPaperPlane,
    faPaperclip,
    faPause,
    faPaw,
    faPenField,
    faPenSquare,
    faPeopleArrows,
    faPeopleCarry,
    faPerson,
    faPersonBurst,
    faPersonCircleCheck,
    faPersonCircleExclamation,
    faPersonCirclePlus,
    faPersonFalling,
    faPersonFallingBurst,
    faPersonRays,
    faPhoneSlash,
    faPlay,
    faPlayCircle,
    faPlus,
    faPoll,
    faPollH,
    faPollPeople,
    faPortalExit,
    faPresentation,
    faPrint,
    faQuestionCircle,
    faQuestionSquare,
    faRedoAlt,
    faRepeat,
    faReply,
    faReplyAll,
    faRestroom,
    faRunning,
    faSackDollar,
    faSave,
    faScaleBalanced,
    faSchool,
    faScreenUsers,
    faScroll,
    faSearch,
    faServer,
    faShapes,
    faShareAlt,
    faShoppingBasket,
    faSignIn,
    faSignOut,
    faSignOutAlt,
    faSitemap,
    faSlidersHSquare,
    faSortNumericDownAlt,
    faStamp,
    faStop,
    faStopCircle,
    faSuitcaseRolling,
    faSync,
    faTable,
    faTasks,
    faText,
    faThList,
    faTimes,
    faTimesCircle,
    faTimesSquare,
    faTrashAlt,
    faTrashRestore,
    faTriangleExclamation,
    faUndo,
    faUndoAlt,
    faUpload,
    faUser,
    faUserAltSlash,
    faUserChart,
    faUserCheck,
    faUserCircle,
    faUserEdit,
    faUserGraduate,
    faUserGroup,
    faUserMinus,
    faUserPlus,
    faUserPolice,
    faUserSlash,
    faUserTag,
    faUserTimes,
    faUsers,
    faUsersClass,
    faUsersCog,
    faUsersMedical,
    faUsersSlash,
    faVideo,
    faWallet,
    faWeightScale,
    faWindowMaximize,
    faWindowRestore,
    faStar as falStar,
} from '@fortawesome/pro-light-svg-icons';
import {
    faBusSchool,
    faCaretDown,
    faCaretUp,
    faChalkboard,
    faCircle,
    faCircleCheck,
    faCloudDownloadAlt,
    faDownLeftAndUpRightToCenter,
    faGear,
    faHandshakeSimple,
    faMapMarkerAlt,
    faMessageExclamation,
    faPersonCircleQuestion,
    faSpinnerThird,
    faStarHalfAlt,
    faUserDoctorMessage,
    faUserShield,
    faVolumeSlash,
    faVolumeUp,
    faFolder as fasFolder,
    faLink as fasLink,
    faStar as fasStar,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faFacebook,
    faGoogle,
    faGoogleDrive,
    faGooglePlay,
    faMicrosoft,
    faWhatsapp,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';

// eslint-disable-next-line func-style
export function initIcons(library: FaIconLibrary): void {
    library.addIcons(
        faWeightScale,
        faArrowUp91,
        faCakeCandles,
        faUserPolice,
        faPersonCircleExclamation,
        faUserDoctorMessage,
        faAsterisk,
        faPersonCircleCheck,
        faPeopleArrows,
        faPerson,
        faMessageExclamation,
        faEnvelopeCircleCheck,
        faUserShield,
        faPersonCircleQuestion,
        faSuitcaseRolling,
        faBusSchool,
        faCarSide,
        faFamily,
        faLightEmergencyOn,
        faLineColumns,
        faFileChartColumn,
        faScaleBalanced,
        faShapes,
        faPersonBurst,
        faPersonRays,
        faArrowUp,
        faFiles,
        faGear,
        faListRadio,
        faGearComplexCode,
        faMaximize,
        faMinimize,
        faBadgeCheck,
        faCheckDouble,
        faMapMarkerAlt,
        faCircleChevronLeft,
        faCircleChevronRight,
        faArrowToLeft,
        faArrowToRight,
        faPhoneSlash,
        faCamcorder,
        faBook,
        faBookOpen,
        faWhatsapp,
        faPenSquare,
        faPenField,
        faArchive,
        faFolderTree,
        faAnalytics,
        faHdd,
        faGoogleDrive,
        faGooglePlay,
        faPrint,
        faServer,
        faMicrosoft,
        faGoogle,
        faFacebook,
        faYoutube,
        faUserAltSlash,
        faUserCheck,
        faQuestionSquare,
        faList,
        faRepeat,
        faUserMinus,
        faCommentDots,
        faCommentPlus,
        faChevronDown,
        faChevronUp,
        faCompressAlt,
        faExpandAlt,
        faFileSignature,
        faFilePdf,
        faFileVideo,
        faFileWord,
        faFileImage,
        faFileAudio,
        faFileExcel,
        faFileArchive,
        faFilePowerpoint,
        faMicrophone,
        faCircle,
        faCalendarTimes,
        faCalendarWeek,
        faUsersSlash,
        faUserChart,
        faVideo,
        faPortalExit,
        faHandPointer,
        faChevronDoubleRight,
        faChevronDoubleLeft,
        faEnvelopeOpenDollar,
        faUserPlus,
        faEraser,
        faCogs,
        faMoneyBill,
        faExchange,
        faInfo,
        faEnvelope,
        faRunning,
        faFileImport,
        faDigging,
        faClipboardList,
        faTimesSquare,
        faWindowRestore,
        faWindowMaximize,
        faFileExport,
        faSignOutAlt,
        faSave,
        faSync,
        faPeopleCarry,
        faCalendar,
        faEye,
        faEyeSlash,
        faSignOut,
        faKey,
        faUserCircle,
        faAngleDown,
        faArrowDown,
        faBars,
        faLanguage,
        faEllipsisV,
        faPlus,
        faEdit,
        faTrashAlt,
        faTimes,
        faSearch,
        faHome,
        faCheck,
        faCommentsAltDollar,
        faCity,
        faCommentAltDollar,
        faObjectGroup,
        faSitemap,
        faCalendarDay,
        faUsersCog,
        faRestroom,
        faCalendarAlt,
        faCalendarEdit,
        faHandsUsd,
        faMoneyCheckAlt,
        faChalkboardTeacher,
        faChalkboard,
        faMoneyCheckEdit,
        faTasks,
        faBallotCheck,
        faAngleLeft,
        faArrowRight,
        faArrowLeft,
        faAlarmClock,
        faAngleRight,
        faBookReader,
        faCamera,
        faFileCertificate,
        faChevronRight,
        faChevronLeft,
        faCheckCircle,
        faExclamationTriangle,
        faFileExcel,
        faFunction,
        faFolder,
        faFolderOpen,
        faFolderPlus,
        faInfoCircle,
        faPage,
        faSchool,
        faSignIn,
        faTimesCircle,
        faUser,
        faUserEdit,
        faUpload,
        faGlobeEurope,
        faUserTag,
        faFlag,
        faListAlt,
        faListOl,
        faListUl,
        faDownload,
        faFileInvoiceDollar,
        faSortNumericDownAlt,
        faGraduationCap,
        faPaperclip,
        faMoneyCheckEditAlt,
        faUsersClass,
        faLocation,
        faBuilding,
        faUsers,
        faUserGraduate,
        faHandHoldingUsd,
        faPollPeople,
        faWallet,
        faHistory,
        faUndoAlt,
        faUndo,
        faUserTimes,
        faArrowCircleDown,
        faBoxes,
        faCubes,
        faCube,
        faBrush,
        faShareAlt,
        faPause,
        faStop,
        fasStar,
        falStar,
        faStarHalfAlt,
        faPlay,
        faSackDollar,
        faCheckSquare,
        faUserSlash,
        faThList,
        faExclamationSquare,
        faEnvelopeOpenText,
        faText,
        faCopy,
        faClock,
        faPresentation,
        faBan,
        faBell,
        faBellSlash,
        faScroll,
        faCog,
        faRedoAlt,
        faEnvelopeOpen,
        faCloudDownload,
        faCloudDownloadAlt,
        faCloudUpload,
        faPaperPlane,
        faInboxIn,
        faInboxOut,
        faReplyAll,
        faShoppingBasket,
        faMarker,
        faReply,
        faCommentAltLines,
        faFile,
        faAddressCard,
        faCommentsAlt,
        faFont,
        faSlidersHSquare,
        faMap,
        faComment,
        faLock,
        faLockOpen,
        faExclamationCircle,
        faCalendarCheck,
        faUsersMedical,
        faLayerGroup,
        faLink,
        faTrashRestore,
        faPlayCircle,
        faPollH,
        faMinus,
        faQuestionCircle,
        faAlarmPlus,
        faVolumeUp,
        faVolumeSlash,
        faSpinnerThird,
        faFileCheck,
        faCircleCheck,
        faChartBar,
        faTable,
        faHomeAlt,
        faLockOpen,
        faCircleSort,
        faChartSimple,
        faIdBadge,
        faBellPlus,
        faDownLeftAndUpRightToCenter,
        faPager,
        faUserGroup,
        faExternalLinkAlt,
        faHandshakeSimple,
        faBracketsCurly,
        faCircleQuestion,
        faMemoCircleInfo,
        faPoll,
        faStamp,
        faCertificate,
        faMusic,
        faImage,
        faLocationDot,
        faStopCircle,
        faPaw,
        faFilm,
        faPersonFallingBurst,
        faPersonFalling,
        fasFolder,
        faArrowsRotate,
        faScreenUsers,
        faCodeCompare,
        faCalendarCircleUser,
        faCancel,
        faTriangleExclamation,
        faListTimeline,
        faCaretUp,
        faCaretDown,
        faGavel,
        faArrowRotateRight,
        faHighlighterLine,
        fasLink,
        faPersonCirclePlus
    );
}
